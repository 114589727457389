import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import slugify from "slugify"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

import Seo from "../components/Seo"

const MainCategoryTemplate = ({ pageContext, data }) => {
  const { edges, distinct } = data.allShopifyProduct

  const uniques = distinct.map((pType, i) =>
    edges.find(product => product.node.productType === distinct[i])
  )

  const slugifiedNavItem = slugify(pageContext.navItem, {
    lower: true,
    locale: "de",
  })

  return (
    <>
      <Seo
        title={`${pageContext?.navItem} - ${process.env.GATSBY_SHOP_OWNER} Onlineshop`}
        description={`Alle Produkte aus der Kategorie ${pageContext?.navItem} im ${process.env.GATSBY_SHOP_OWNER} Onlineshop`}
      />
      <BreadcrumbWrapper>
        <Breadcrumb to="/shop">Shop</Breadcrumb>
        <span className="breadcrumb-divider">|</span>
        <Location>{pageContext.navItem}</Location>
      </BreadcrumbWrapper>

      <h1 style={{ textAlign: "center", margin: "3rem 0" }}>
        {pageContext.navItem}
      </h1>
      <ProductTypeGrid>
        {data?.allShopifyProduct?.distinct?.map((type, i) => {
          const currentType = uniques.find(
            element => element.node.productType === type
          )
          const image = getImage(currentType.node.featuredImage)

          const slugifiedProductType = slugify(type, {
            lower: true,
            locale: "de",
          })

          return (
            <motion.div
              key={i}
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, delay: i * 0.1 }}
            >
              <ProductTypeCard>
                <Link to={`/shop/${slugifiedNavItem}/${slugifiedProductType}`}>
                  <motion.div
                    whileHover={{
                      scale: 1.02,
                      textShadow: "0px 0px 4px gray",
                    }}
                  >
                    <GatsbyImage image={image} alt={type} />
                  </motion.div>
                  <span>{type}</span>
                </Link>
              </ProductTypeCard>
            </motion.div>
          )
        })}
      </ProductTypeGrid>
    </>
  )
}

export const query = graphql`
  query MainNavQuery($vendor: String!, $navItem: String!) {
    allShopifyProduct(
      filter: {
        mediaCount: { gte: 1 }
        vendor: { eq: $vendor }
        metafields: { elemMatch: { value: { eq: $navItem } } }
      }
    ) {
      distinct(field: productType)
      edges {
        node {
          productType
          featuredImage {
            gatsbyImageData(aspectRatio: 1, width: 300, height: 300)
          }
        }
      }
    }
  }
`

const ProductTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  justify-content: center;
  margin: auto;
  gap: 3rem 1rem;
  margin-bottom: 4rem;
  padding: 0 1rem;

  .productTypeCard {
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text};
      font-family: ${({ theme }) => theme.fontHeading};
      font-size: 1.5rem;
    }

    span {
      display: block;
      text-align: center;
      margin-top: 1rem;
    }
  }
`

const ProductTypeCard = styled.div`
  text-align: center;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontHeading};
    font-size: 1.5rem;
  }

  span {
    display: block;
    text-align: center;
    margin-top: 1rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
  }
`

const BreadcrumbWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 0.9rem;
  margin: 2rem 2rem 0;
  white-space: nowrap;
  overflow-x: auto;
  padding: 0.5rem 0;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  a {
    /* margin-right: 0.5rem; */
    color: ${({ theme }) => theme.colors.textLight};

    :hover {
      /* text-decoration: underline; */
    }
  }

  span {
    margin-right: 0.5rem;
  }

  .breadcrumb-divider {
    display: inline-block;
    margin: 0 0.5rem;
  }

  @media (max-width: 500px) {
    margin-bottom: 1rem;
  }
`

const Breadcrumb = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`

const Location = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.text};
`

export default MainCategoryTemplate
